(<template>
  <router-link class="project-card__wrapper"
               :to="$makeRoute(projectRoute)">
    <section class="project-card"
             :style="cardBg">
      <header class="project-card__header">
        <div class="project-card__header-content">
          <div class="icon project-card__icon--clip-board"></div>
          <div>
            <span v-if="data.publicId"
                  class="font-primary project-card__id">#{{ data.publicId }}</span>
            <span v-else
                  class="font-primary project-card__id">#{{ data.id }}</span>
            <span v-if="projectSubtasksTypes && userIsNotInterpreter"
                  class="font-primary project-card__subtasks-types">{{ subtasksTypes }}</span>
            <p v-if="data.subtaskType"
               class="project-card__subtask-type">{{ subtaskType }}</p>
          </div>
          <h4 v-if="showName"
              class="project-card__title"> {{ data.name }}</h4>
        </div>
        <project-status :data="data"
                        :status-style="cardStyle" />
      </header>
      <div class="project-card__content">
        <div class="project-card__countries">
          <div class="icon project-card__icon--globe"></div>
          <dotted-bullet :source-lang="sourceLanguage"
                         :target-langs="targetLanguages" />
        </div>
        <div class="project-card__content-right">
          <p v-if="data.attachmentsNumber"><span class="icon project-card__icon--clip-board"></span>{{ data.attachmentsNumber }} {{ documentsText }}</p>
          <p v-if="data.countOfWords">{{ wordsText }}: <b class="font-primary">{{ data.countOfWords }}</b></p>
          <p v-if="showInternalDeadline || showExternalDeadline"
             :class="{'deadline-warning': timeToDeadline.status && showInternalDeadline }">
            <span class="icon project-card__icon-clock"
                  :class="{'project-card__icon-clock--red': timeToDeadline.status && showInternalDeadline }"></span>
            <b>{{ deadlineDate }}<span class="font-primary">&nbsp;({{ timeToDeadline.message }})</span></b>
          </p>
        </div>
      </div>
      <div v-if="isProactiveAccesProject"
           class="project-card__actions">
        <a v-if="data.initialAttachmentsPresent"
           class="sk-link project-card__download-btn"
           @click="handleDownloadClick($event)">{{ downloadFilesText }}</a>
        <button v-if="showButtonClaim"
                class="sk-btn--default project-card__action-btn"
                @click="openModalClaimProject($event)">{{ claimJobText }}</button>
        <p v-else
           class="project-card__success">{{ $gettext('Assignment claimed') }}</p>
      </div>
    </section>
  </router-link>
</template>)

<script>
  import DottedBullet from '@/components/project_components/list/DottedBullet';
  import ProjectStatus from '@/components/project_components/list/ProjectStatus';
  import helpers from '@/helpers';
  import constants from '@/modules/constants';
  import {mapGetters} from 'vuex';

  export default {
    name: 'single-project',
    components: {ProjectStatus, DottedBullet},
    props: {
      data: {
        type: Object,
        default: () => {}
      },
      colorPallets: {
        type: Function,
        default: () => ({})
      },
      showName: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        showButtonClaim: true
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsNotInterpreter']),
      downloadFilesText() { return this.$pgettext('translation', 'Download all files'); },
      claimJobText() { return this.$pgettext('translation', 'Claim Assignment'); },
      languagesList() { return this.$store.state.selectsData.language || []; },
      sourceLanguage() {
        return this.data.sourceLanguageId
          ? this.languagesList.find((lang) => lang.id === this.data.sourceLanguageId)
          : {name: '', id: ''};
      },
      targetLanguages() {
        return this.data.targetLanguages.map((item) => {
          return this.languagesList.find((lang) => lang.id === item);
        });
      },
      getGivenMoment() {
        return this.$moment(this.data.externalDeadline || this.data.internalDeadline);
      },
      deadlineDate() { return this.getGivenMoment.format('DD.MM.YY'); },
      isProactiveAccesProject() { return this.data.status === 'proactive_access'; },
      timeToDeadline() {
        const hoursDifference = this.getGivenMoment.diff(this.$moment(), 'hours');
        const daysDifference = this.getGivenMoment.diff(this.$moment(), 'days');
        let {message, status} = {
          message: '',
          status: null
        };

        if (hoursDifference < 0) {
          message = this.$gettext('passed');
          status = 'expired';
        } else if (daysDifference > 0) {
          message = `${daysDifference} ${this.$gettext('days')}`;
        } else if (hoursDifference % 24 > 0) {
          message = `${hoursDifference % 24} ${this.$gettext('hours')}`;
          status = 'warning';
        }

        return {message, status};
      },
      projectStatus() {
        return this.userIsNotInterpreter && this.data.isXTRF ? 'quote_accepted' : this.data.status;
      },
      cardStyle() { return this.colorPallets(this.projectStatus); },
      cardBg() {
        if (this.timeToDeadline.status === 'expired' && this.showInternalDeadline) {
          return {
            'background-color': '#ff00440d',
            border: '2px solid #F04'
          };
        }

        return {
          // for white background we have borders
          // for quote requested sent & accepted card bg always white
          'background-color': (this.cardStyle?.bgColor === '#99afbb' || this.cardStyle?.whiteCard) ? '#fff' : this.cardStyle?.bgColor,
          border: (this.cardStyle?.bgColor === '#99afbb' || this.cardStyle?.whiteCard) ? '2px solid rgb(224, 219, 219)' : '2px solid transparent'
        };
      },
      wordsText() { return this.$pgettext('translation', 'Words'); },
      documentsText() {
        if (this.data.attachmentsNumber === 1) return this.$pgettext('translation', 'Document');
        else return this.$pgettext('translation', 'Documents');
      },
      subtaskType() {
        return helpers.getProjectInfo.subtaskType(this, this.data.subtaskType);
      },
      subtasksTypes() {
        return '\u2022 ' + this.projectSubtasksTypes;
      },
      projectSubtasksTypes() {
        return helpers.getProjectInfo.subtasksTypes(this, this.data.subtaskTypes || []);
      },
      projectRoute() {
        const prefix = this.$store.state.UserInfoStore.mainUserInfo.role == 'server' ? 'Server' : 'Buyer';
        if (prefix === 'Server') {
          return {
            name: 'ServerOneProject',
            params: {
              id: this.data.orderId
            }
          };
        } else {
          return {
            name: 'BuyerOneProject',
            params: {
              id: this.data.id
            }
          };
        }
      },
      showInternalDeadline() {
        return this.data.internalDeadline && this.data.status !== 'rejected' && this.data.status !== 'cancelled' && this.data.status !== 'finished';
      },
      showExternalDeadline() {
        return this.data.externalDeadline && this.userIsNotInterpreter;
      }
    },
    methods: {
      reloadPage() {
        window.location.reload();
      },
      downLoadFiles(link) {
        const a = document.createElement('a');
        a.href = link;
        a.target = '_blank';
        a.click();
      },
      handleDownloadClick(event) {
        event.preventDefault();

        this.$store.commit('ModalStore/setModal', {
          component: 'confirm-download-modal',
          data: {
            title: this.$gettext('Confirm downloading file'),
            text: this.$gettext('Translator accepts that he/she will have access to the files before he/she accepts the order. If the translator rejects, he/she agrees to delete all the files he/she previously downloaded that are associated with this order.'),
            context: this,
            modalCallback: this.downLoadFiles,
            callbackParams: `${constants.API_PATH}/v1/subtasks/${this.data.id}/attachments_zip?zip_file_name=${this.data.publicId || this.data.id}-MySalita&authorization_token=${this.$fetcher.userToken}`,
          }
        });
      },
      openModalClaimProject(event) {
        event.preventDefault();

        const template = this.$gettext('You accept the deadline: %{deadline}');
        const modalContent = `<p>
          ${this.$gettext('By continuing you confirm that:')}
          <ul class="confirmation-list">
            <li>${this.$gettext('You have reviewed the information provided in this assignment')}</li>
            <li>${this.$gettext('You are able to complete this assignment')}</li>
            ${this.deadlineDate ? `<li>${this.$gettextInterpolate(template, {deadline: this.deadlineDate})}</li>` : ''}
            <li>${this.$gettext('Salita reserves the right to withdraw the assignment if the translator does not comply with the points above')}</li>
          </ul>
        </p>`;

        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Claim assignment'),
            text: modalContent,
            context: this,
            modalCallback: this.claimProject,
            btnTexts: {
              actionBtnText: this.$gettext('Confirm and claim'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      claimProject() {
        const params = {
          id: this.data.id,
          subtask: {
            status: 'accepted',
            review_comment_from_seller: ''
          }
        };

        const errorText = `<div>
          <p class="error-text-item">${this.$gettext('We apologize for the inconvenience.')}</p>
          <p class="error-text-item">${this.$gettext('This assignment was made available to all our preferred translators. The time period when these assignments can be claimed is limited.')}</p>
          <p class="error-text-item">${this.$gettext('Unfortunately it can no longer be claimed without a direct invitation.')}</p>
        </div>`;

        this.$emit('startprogress');
        this.$store.dispatch('OneProjectStore/changeSubtaskStatus', params).then(() => {
          this.$emit('stopprogress');
          this.showButtonClaim = false;

          setTimeout(() => {
            this.reloadPage();
          }, 1000);
        }).catch((error) => {
          this.$emit('stopprogress');

          if (error.status == 401 || error.status == 422) {
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'confirmation-modal',
                classes: ['sk-modal--new'],
                disableClose: true,
                data: {
                  title: this.$gettext('The assignment can no longer be claimed'),
                  text: errorText,
                  context: this,
                  modalCallback: this.reloadPage,
                  btnTexts: {
                    actionBtnText: this.$gettext('Go back')
                  },
                  visibility: {
                    hideCancelBtn: true
                  }
                }
              });
            }, 0);
          } else {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal'
            });
          }
        });
      },
    }
  };
</script>

<style scoped>
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
}

.project-card__icon--clip-board {
  background-image: var(--image-clipboard-icon);
}

.project-card__icon--globe {
  background-image: var(--image-globe-icon);
}

.project-card__icon-clock {
  margin-bottom: 3px;
  background-image: var(--image-clock-icon);
}

.project-card__icon-clock--red {
  margin-bottom: 3px;
  background-image: var(--image-clock-icon-red);
}

.font-primary {
  font-weight: bold;
  font-size: 15px;
}

.project-card {
  margin-bottom: 15px;
  padding: 5px 5px 5px 25px;
  border: solid 1px #ccd7dd;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.project-card:not(:last-child) {
  margin-bottom: 15px;
}

.project-card__header {
  margin-bottom: 20px;
}

.seller-projects .project-card__header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.project-card__header-content {
  position: relative;
}

.project-card__id {
  margin-bottom: 10px;
}

.project-card__subtask-type {
  margin: 8px 0;
  font-size: 15px;
}

.project-card__title {
  margin-bottom: 15px;
  color: black;
  font-weight: normal;
  font-size: 15px;
}

.project-card__countries {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 20px;
}

.project-card__countries .project-card__icon--globe {
  position: absolute;
  top: 5px;
  left: -21px;
}

.project-card__content-right p {
  width: 100%;
  max-width: calc(50% - 40px);
  margin-bottom: 10px;
  white-space: nowrap;
}

.project-card__content-right p:not(:last-child) {
  margin-right: 40px;
}

.project-card__header-content .project-card__icon--clip-board {
  position: absolute;
  top: 2px;
  left: -20px;
}

.deadline-warning {
  color: #f04;
  color: var(--color-error);
}

.project-card__actions {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.project-card__download-btn {
  margin-right: auto;
}

.project-card__action-btn {
  margin-left: auto;
  padding: 0 20px;
  border-radius: 3px;
  line-height: 33px;
}

.project-card__success {
  padding-left: 25px;
  background-image: url(~@assets/imgs/undefined_imgs/check_icon_green.svg);
  background-position: 3% center;
  background-size: 16px auto;
  background-repeat: no-repeat;
  line-height: 33px;
}

@media (min-width: 600px) {
  .project-card {
    padding: 8px 8px 8px 33px;
  }

  .project-card:hover {
    top: -4px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(38, 38, 38, 0.2);
    transition: all 0.3s ease-out;
    transform: translateY(-1px);
  }

  .project-card__wrapper:focus > .project-card {
    top: -4px;
    border: 2px solid #000 !important;
    box-shadow: 0 4px 8px rgba(38, 38, 38, 0.2);
    transition: all 0.3s ease-out;
    transform: translateY(-1px);
  }

  .project-card__header {
    display: flex;
    margin-bottom: 5px;
  }

  .project-card__title {
    margin-bottom: 0;
    padding-right: 20px;
  }

  .project-card .project-status {
    align-self: flex-start;
    margin-left: auto;
  }

  .project-card__content {
    display: flex;
  }

  .project-card__countries {
    flex: 1 1 auto;
    min-width: 200px;
    max-width: 535px;
    margin-bottom: 0;
  }

  .project-card__content-right {
    display: flex;
    flex-grow: 0;
    align-self: flex-end;
    justify-content: flex-end;
    margin-left: auto;
  }

  .project-card__content-right p {
    width: auto;
    max-width: 100%;
    margin-bottom: 0;
  }

  .project-card__content-right p:not(:first-child) {
    margin-left: 25px;
  }

  .project-card__content-right p:not(:last-child) {
    margin-right: 0;
  }

  .project-card__icon--clip-board {
    left: -25px;
    margin-bottom: 3px;
  }

  @media (min-width: 768px) {
    .project-card__header-content {
      display: flex;
    }

    .project-card__title {
      margin-left: 20px;
    }
  }

  @media (min-width: 1024px) {
    .project-card {
      padding: 15px 15px 15px 40px;
    }

    .project-card__title {
      margin-left: 10px;
    }
  }
}

@media (min-width: 768px) {
  .seller-projects .project-card__countries {
    min-width: 134px;
  }

  .seller-projects .project-card__content-right {
    flex-wrap: wrap;
  }
}
</style>

<style>
.confirmation-list {
  margin: 20px 0 0 32px;
}

.error-text-item {
  margin-bottom: 15px;
}
</style>
