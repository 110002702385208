<template>
  <ul class="bullet">
    <li v-if="pairHasSourceLang"
        class="bullet__item">
      <h3 class="bullet__title">{{ sourceLang.name }}
        <span v-if="sourceLang.code"
              class="bullet__title--code">{{ sourceLang.code }}</span>
      </h3>
    </li>
    <li :class="{'bullet__item': pairHasSourceLang }">
      <h3 class="bullet__title"
          :class="{'bullet__title--single': !pairHasSourceLang }">{{ targetLanguagesConcat }}
        <span v-if="targetLangs.code"
              class="bullet__title--code">{{ targetLangs.code }}</span>
      </h3>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'dotted-bullet',
    props: {
      sourceLang: {
        type: Object,
        required: true
      },
      targetLangs: {
        type: Array,
        required: true
      }
    },
    computed: {
      pairHasSourceLang() {
        return this.sourceLang.name;
      },
      targetLanguagesConcat() {
        return this.targetLangs?.map((lang) => lang?.name).join(', ');
      }
    }
  };
</script>

<style scoped>

.bullet {
  margin-left: 10px;
  list-style: none;
}

.bullet__item {
  position: relative;
  padding-left: 5px;
}

.bullet__item:not(:last-child) {
  padding-bottom: 14px;
}

.bullet__item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 14px;
  left: -10px;
  width: 2px;
  height: 100%;
  border-radius: 50%;
  background: var(--image-dots) repeat-y top;
  font-weight: bold;
  line-height: 6px;
  transform: translateX(2px);
}

.bullet__item::before {
  content: "";
  position: absolute;
  top: 8px;
  left: -10px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000;
}

.bullet__title {
  font-weight: bold;
  font-size: 16px;
  word-break: break-word;
}

.bullet__title--code {
  font-weight: 400;
}

.bullet__title--single {
  margin-top: 3px;
}
</style>
