<template>
  <div class="project-status"
       :style="bgColor">
    {{ getStatusText }}
  </div>
</template>

<script>
  import helpers from '@/helpers';
  import {mapGetters} from 'vuex';

  export default {
    name: 'project-status',
    props: {
      data: {
        type: Object,
        default: () => {}
      },
      statusStyle: {
        type: Object,
        default: () => {}
      },
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter'
      ]),
      bgColor() {
        return {
          'background-color': this.statusStyle?.bgColor,
          color: this.statusStyle?.textColor
        };
      },
      waitingForCustomer() { return this.data.waitingForCustomer; },
      status() {
        if (this.waitingForCustomer) return 'waiting_for_customer';
        else if (this.userIsNotInterpreter && this.data.isXTRF) return 'quote_accepted';
        else return this.data.status;
      },
      getStatusText() {
        return helpers
          .getProjectInfo
          .getProjectStatus(
            this,
            {status: this.status}
          );
      }
    },
  };
</script>

<style scoped>
.project-status {
  display: inline-block;
  padding: 5px 8px;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #000;
  text-align: center;
}

</style>
